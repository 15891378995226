import React, { useEffect, useState, useCallback } from "react";
import "./Home.css";
import Grid from "../constants/Grid";
import { buildGetData, buildPostData, getFullURL, API_PATH } from "../constants/constants";
import { useLoading } from "../constants/LoadingContext";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";

const Items = () => {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [msgModalOpen, setMsgModalOpen] = useState(false); 
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await buildGetData(getFullURL(API_PATH.PRODUCTS_LIST_URL));
      if (res?.data?.status === "success") {
        setItems(res.data.result.rows);
        setIsLoading(false);
      } else {
        console.error("Unexpected response:", res);
        alert("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    init();
  }, [init]);

  const columnData = [
    { headerName: "Product No", field: "productNo" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Created Date", field: "createdDate" },
    { headerName: "Updated Date", field: "updatedDate" },
    { headerName: "Status", field: "status" },
  ];

  const searchFields = ["productNo", "productName"];

  const processedItems = items.map((item) => ({
    ...item,
    status: item.status === 0 ? "Active" : "Inactive",
  }));

  const handleAction = (actionType, rowData) => {
    if (actionType === "delete") {
      setSelectedItem(rowData);
      setIsModalOpen(true);
    }
  };

  const handleDelete = async () => {
    if (selectedItem) {
      setIsLoading(true);
      try {
        const deleteResponse = await buildPostData(getFullURL(`/products/${selectedItem.productId}/deleteproduct`));
        if (deleteResponse?.data?.status === "success") {
          init();
          setIsModalOpen(false);
          setMsgModalOpen(true);
        } else {
          console.error("Failed to delete:", deleteResponse);
          alert("Failed to delete Product. Please try again.");
        }
      } catch (error) {
        console.error("Error during deletion:", error);
        alert("An error occurred while deleting the Product.");
      } finally {
        setIsLoading(false);
        setSelectedItem(null);
      }
    }
  };

  const actions = [
    {
      name: "Delete",
      icon: <FaTrash />,
      color: "#dc3545",
      callback: (rowData) => handleAction("delete", rowData),
    },
  ];

 // Custom modal component for success message
const SuccessModal = ({ message, onClose }) => {
  return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            textAlign: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <p style={{ fontWeight: 'bold', margin: '10px' }}>{message}</p>
          <button
            style={{
              padding: "8px 16px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
  );
};

  return (
    <div className="home">
      {/* Create Button Positioned Above the Grid */}
      <div style={{ marginBottom: "10px", textAlign: "right" }}>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "5px",
            marginRight: "11px",
          }}
          onClick={() => navigate("/CreateProducts")}
        >
          Create
        </button>
      </div>

      <Grid
        rowData={processedItems}
        columnData={columnData}
        searchFields={searchFields}
        excelName="Products"
        actions={actions}
      />

      {/* Delete Confirmation Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p style={{ fontWeight: 'bold', margin: '10px' }}>
              Are you sure you want to delete this Product?
            </p>
            <div style={{ display: "flex", justifyContent: "center", gap: "30px", marginTop: "35px" }}>
              <button
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => {handleDelete()}}
              >
                Yes
              </button>
              <button
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsModalOpen(false);
                  setSelectedItem(null);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {msgModalOpen && ( // Use the same state variable for both modals
        <SuccessModal message="Product Deleted Successfully" onClose={() => setMsgModalOpen(false)} />
      )}
    </div>
  );
};

export default Items;