import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { FaDownload } from "react-icons/fa";

const Grid = ({
  rowData,                        // Row data Array
  columnData,                     // column Headers Array
  searchFields,                   // search fields Array
  excelName = "export",           // Excel name
  actions = [],                   // Action Buttons Array
  clickableFields = [],           // Add this prop
  onFieldClick = () => {},        // Callback for field clicks
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const gridRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = () => {
    if (!searchQuery) return rowData;

    return rowData.filter((item) => {
      return searchFields.some((field) => {
        return item[field]?.toString().toLowerCase().includes(searchQuery.toLowerCase());
      });
    });
  };

  // Function to handle Excel export
  const exportToCSV = () => {
    const filteredData = filterData();
    const columnHeaders = columnData.map((col) => col.headerName);
    const csvData = [columnHeaders];
  
    // Extract rows from filteredData
    filteredData.forEach((row) => {
      const rowData = columnData.map((col) => row[col.field] || "");
      csvData.push(rowData);
    });
  
    // Convert the data to a CSV format
    const csvContent = csvData
      .map((row) => row.map((value) => `"${value}"`).join(","))
      .join("\n");
  
    // Create a Blob and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
  
    link.setAttribute("href", url);
    link.setAttribute("download", `${excelName}_data.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  // Action Cell Renderer
  const actionCellRenderer = (params) => {
    return (
      <div style={{ display: "flex", gap: "5px", margin: "5px" }}>
        {actions.map((action, index) => (
          <button
            key={index}
            onClick={() => action.callback(params.data)}
            style={{
              padding: "5px",
              backgroundColor: "#fff",
              color: action.color || "#007bff",
              border: `1px solid ${action.color || "#007bff"}`,
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {action.icon && <span>{action.icon}</span>}
            {action.name}
          </button>
        ))}
      </div>
    );
  };

  // Make specified fields clickable
  const updatedColumnData = [
    ...columnData.map((col) => {
      const isClickable = clickableFields.some((field) => field.field === col.field);
      if (isClickable) {
        return {
          ...col,
          cellRenderer: (params) => (
            <span
              style={{
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                const clickedField = clickableFields.find((field) => field.field === col.field);
                onFieldClick(clickedField, params.data); // Pass field info and row data
              }}
            >
              {params.value}
            </span>
          ),
        };
      }
      return col;
    }),
    ...(actions.length > 0
      ? [
          {
            headerName: "Actions",
            field: "actions",
            cellRenderer: actionCellRenderer,
          },
        ]
      : []),
  ];

  return (
    <div className="grid-container" style={{ width: "100%" }}>
      <div
        className="ag-theme-alpine"
        style={{
          height: 600,
          width: "100%",
          padding: "3px 10px 10px 10px",
        }}
      >
        {/* Toolbar */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 15px",
            backgroundColor: "#f4f4f4",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div style={{width:'200%'}}>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{
              padding: "5px 10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontSize: "14px",
              width: "220px",
            }}
          />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 10 }}>Total:</span>
            <span style={{ marginRight: 15 }}>{filterData().length}</span>
            <FaDownload
              onClick={exportToCSV}
              style={{ cursor: "pointer", fontSize: "15px" }}
            />
          </div>
        </div>

        {/* Grid */}
        <AgGridReact
          ref={gridRef}
          rowData={filterData()}
          columnDefs={updatedColumnData}
        />
      </div>
    </div>
  );
};

export default Grid;
