import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CreateSalesOrder.css";
import {
  buildGetData,
  getFullURL,
  API_PATH,
  buildPostData,
} from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";

const EditSalesOrder = () => {
  const { id  } = useParams(); 
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const [formData, setFormData] = useState({
    customerName: { value: 0, text: "" },
    deliveryDate: "",
    salesOrderNo: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [errors, setErrors] = useState({});
  const [itemsError, setItemsError] = useState("");
  const [itemValidationErrors, setItemValidationErrors] = useState([]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        setIsLoading(true);
        const res = await buildGetData(getFullURL(`/sales/${id}/editform`));
        if (res?.data?.status === "success") {
            prefillData(res.data.result);
          } else {
            alert("Failed to fetch order details.");
          }
          
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchItems = async () => {
        try {
          const res = await buildGetData(getFullURL(API_PATH.PRODUCTS_LIST_URL));
          if (res?.data?.status === "success") {
            setProductList(res.data.result.rows);
          } else {
            alert("Failed to fetch products.");
          }
        } catch (error) {
          console.error("Error fetching products:", error);
          alert("An error occurred while fetching the products.");
        }
      };
    
    fetchItems();
    fetchOrderDetails();
  }, [id , setIsLoading]);

  function prefillData(data) {
    setFormData({
        customerName: {
          value: data.customerId, 
          text: data.customerName, 
        },
        deliveryDate: new Date(data?.deliveryDate), 
        salesOrderNo: data.salesOrderNo
      });
    
      setSelectedItems(
        data?.lineItems.map((item) => ({
          lineItemId: item.lineItemId,
          productId: item.productId, 
          productNo: item.productNo, 
          productName: item.productName, 
          quantity: item.qty, 
          uom: item.uom, 
          notes: item.notes,
          specialComments: item.specialComments,
          isOtherUOM: item.uom === "Other", 
        }))
      );
  }

  const validateItems = () => {
    const errors = selectedItems.map((item) => {
      const itemError = {};
      if (item.productNo === "") {
        itemError.productName = "Invalid Product Name";
      }
      if (!item.productName || item.productName.trim() === "") {
        itemError.productName = "Product Name is required";
      }
      if (!item.quantity || parseFloat(item.quantity) <= 0) {
        itemError.quantity = "Quantity is required and must be greater than 0";
      }
      if (!item.uom || item.uom.trim() === "") {
        itemError.uom = "UOM is required";
      } else if (item.uom === "Other" && (!item.notes || item.notes.trim() === "")) {
        itemError.uom = "Please Enter notes";
      }
      return Object.keys(itemError).length > 0 ? itemError : null;
    });
    return errors;
  };

  console.log(productList)
  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});
    setItemsError("");
    let isValid = true;

    const newErrors = {};
    if (!formData.customerName.text) {
      newErrors.customerName = "Customer Name is required.";
      isValid = false;
    }
    if (!formData.deliveryDate) {
      newErrors.deliveryDate = "Delivery Date is required.";
      isValid = false;
    }
    setErrors(newErrors);

    if (selectedItems.length === 0) {
      setItemsError("Please add at least one item.");
      isValid = false;
    }

    const itemErrors = validateItems();
    const hasItemErrors = itemErrors.some((error) => error !== null);
    if (hasItemErrors) {
      setItemValidationErrors(itemErrors);
      isValid = false;
    }

    if (isValid) {
        const date = new Date(formData.deliveryDate);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${
          date.getDate().toString().padStart(2, '0')
        }/${date.getFullYear()}`;

        const salesData = {
        salesOrderId: id,
        customerName: formData.customerName.text,
        customerId: formData.customerName.value,
        deliveryDate: formattedDate,
        comments: "",
        status: 0,
        lineItems: selectedItems.map((item) => ({
          lineItemId: item.lineItemId || 0,
          productId: item.productId,
          productName: item.productName,
          productNo: item.productNo,
          qty: item.quantity,
          uom: item.uom,
          notes: item.notes,
          specialComments: item.specialComments,
        })),
      };

      try {
        setIsLoading(true);
        const res = await buildPostData(
          getFullURL(API_PATH.SALES_SAVE_URL),
          salesData
        );
        if (res?.data?.status === "success") {
            setShowModal(true);
        } else {
          alert("Failed to update order.");
        }
      } catch (error) {
        console.error("Error updating order:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const addEmptyItem = () => {
    setSelectedItems([
      ...selectedItems,
      {
        lineItemId: 0,
        productId: 0,
        productNo: "",
        productName: "",
        quantity: "",
        uom: "",
        notes: "",
        isOtherUOM: false,
        specialComments: "",
      },
    ]);
  };

  const removeItem = (index) => {
    if (selectedItems.length > 1) {
      setSelectedItems(selectedItems.filter((_, i) => i !== index));
    } else {
      alert("At least one item is required.");
    }
  };
  

  const updateFieldValue = (index, field, value) => {
    const updatedItems = [...selectedItems];
    
    if (field === "uom") {
      if (updatedItems[index].isOtherUOM) {
        updatedItems[index].uom = value;
      } else if (value === "Other") {
        updatedItems[index].isOtherUOM = true;
        updatedItems[index].uom = value;
      } else {
        updatedItems[index].isOtherUOM = false;
        updatedItems[index].uom = value;
      }
    } else if (field === "productName") {
      const matchedProduct = productList.find(
        (product) => product.productName === value
      );
  
      if (matchedProduct) {
        // If product matches, update the selected item's details
        updatedItems[index].productId = matchedProduct.productId;
        updatedItems[index].productNo = matchedProduct.productNo;
        updatedItems[index].productName = matchedProduct.productName;
      } else {
        // If product does not match, show an error or clear the field
        updatedItems[index].productId = 0;
        updatedItems[index].productNo = "";
        updatedItems[index].productName = value; // Keep the user input
        // Optionally set an error for invalid product name
        const newErrors = [...itemValidationErrors];
        newErrors[index] = { ...newErrors[index], productName: "Invalid Product Name" };
        setItemValidationErrors(newErrors);
      }
    }
    /*else if (field === "productNo") {
      const selectedProduct = productList.find(product => product.text === value);
      
      if (selectedProduct) {
        updatedItems[index].productId = selectedProduct.value;
        updatedItems[index].productNo = selectedProduct.text;
        updatedItems[index].productName = selectedProduct.text1;
      } else {
        updatedItems[index].productId = 0; 
        updatedItems[index].productNo = value; 
      }
    }*/
    
    else {
      updatedItems[index][field] = value;
    }

    if (itemValidationErrors.length > 0) {
      const newErrors = [...itemValidationErrors];
      newErrors[index] = { ...newErrors[index], [field]: "" };
      setItemValidationErrors(newErrors);
    }
  
    setSelectedItems(updatedItems);
  };

  return (
    <div className="create-purchase">
      <form onSubmit={handleSubmit} className="purchase-form">
        <h2>Edit Orders</h2>

      {/* Customer Name */}
      <div className="form-row">
        <div className="form-group buyer-section">
          <label>
          Restaurant/Customer Name<span className="required">*</span>
          </label>
          <input
            type="text"
            name="customerName"
            value={formData.customerName.text}
            placeholder="Enter or select customer name"
            readOnly
            disabled
            //list="customerList"
          />
          {/*<datalist id="customerList">
            {customerList.map((customer, index) => (
              <option key={index} value={customer.text} />
            ))}
          </datalist>*/}
          {errors.customerName && <span className="error-message">{errors.customerName}</span>}
        </div>

        <div className="form-group buyer-section" style={{ marginLeft: 60 }}>
          <label>
          Sales order No.
          </label>
          <input
            type="text"
            name="salesOrderNo"
            value={formData.salesOrderNo}
            readOnly
            disabled
          />
        </div>

        {/* Delivery Date */}
        <div className="form-group" style={{ marginLeft: 60 }}>
            <label>
              Delivery Date<span className="required">*</span>
            </label>
            <DatePicker
              selected={formData.deliveryDate}
              onChange={(date) =>
                setFormData((prevData) => ({ ...prevData, deliveryDate: date }))
              }
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              readOnly
              disabled
            />
            {errors.deliveryDate && (
              <span className="error-message">{errors.deliveryDate}</span>
            )}
          </div>
        </div>
       

        {/* Item Table */}
        <div>
          <h2>Line Items</h2>
         
          <table className="item-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>UOM</th>
              <th>Notes</th>
              {/*<th>Special Comments</th>*/}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedItems.map((item, index) => {
              const itemError = itemValidationErrors[index] || {};
            return(
              <tr key={index}>
                <td>
                    {item.lineItemId === 0 ? 
                    <>
                      <input
                      type="text"
                      name="productName"
                      value={item.productName}
                      onChange={(e) =>
                        updateFieldValue(index, "productName", e.target.value)
                      }
                      placeholder="Enter or select Product Name"
                      list="productList"
                    />
                    <datalist id="productList">
                      {productList.map((product, i) => (
                        <option key={i} value={product.productName}>{product.productName}</option>
                      ))}
                    </datalist>
                    </>
                    :
                    <input
                      type="text"
                      value={item.productName}
                      placeholder="Enter product Name"
                      readOnly
                      disabled
                    />}
                {itemError.productName && (
                  <div className="field-error">{itemError.productName}</div>
                )}
                </td>
                <td>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      updateFieldValue(index, "quantity", e.target.value)
                    }
                    placeholder="Enter quantity"
                  />
                {itemError.quantity && (
                  <div className="field-error">{itemError.quantity}</div>
                )}
                </td>
                <td>
                    <select
                      value={item.uom}
                      onChange={(e) =>
                        updateFieldValue(index, "uom", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Punnet">Punnet</option>
                      <option value="Tray">Tray</option>
                      <option value="Bag">Bag</option>
                      <option value="Box">Box</option>
                      <option value="Kg">Kg</option>
                      <option value="Bunch">Bunch</option>
                      <option value="Pcs">Pcs</option>
                      <option value="pocket">pocket</option>
                      <option value="Other">Other</option>
                    </select>
                {itemError.uom && (
                  <div className="field-error">{itemError.uom}</div>
                )}
                </td>
                <td>
                  <input
                    type="text"
                    value={item.notes}
                    onChange={(e) => updateFieldValue(index, "notes", e.target.value)}
                    placeholder="Enter notes"
                  />
                </td>
                {/*<td>
                  <input
                    type="text"
                    value={item.specialComments}
                    onChange={(e) =>
                      updateFieldValue(index, "specialComments", e.target.value)
                    }
                    placeholder="Enter special comments"
                  />
                </td>*/}
                <td>
                  <button
                    type="button"
                    onClick={() => removeItem(index)}
                    className="remove-item-btn"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            )}
            )}
            {selectedItems.length === 0 && (
              <tr>
                <td colSpan="5">No Products added</td>
              </tr>
            )}
          </tbody>
        </table>
        {itemsError && <div className="error-message">{itemsError}</div>}
          <div>
            <button type="button" onClick={addEmptyItem} className="add-item-btn">
              Add Products
            </button>
          </div>

        </div>
          
        {/* Buttons */}
        <div className="form-footer">
          <button type="button" onClick={() => navigate(-1)} className="back-btn">
            Back
          </button>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>

     
      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Order Saved/Updated Successfully</p>
            <button className="modal-btn" onClick={() => {
              setShowModal(false); 
              navigate(-1); 
            }}>
            Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSalesOrder;
