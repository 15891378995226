import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "./CreateSalesOrder.css";
import "react-datepicker/dist/react-datepicker.css";
import { buildGetData, getFullURL, API_PATH, buildPostData } from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";

const CreateSalesOrder = () => { 
  //const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const { setIsLoading } = useLoading(); 
  const [showModal, setShowModal] = useState(false);
  // Validation state
  const [itemsError, setItemsError] = useState("");
  const [itemValidationErrors, setItemValidationErrors] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    Array.from({ length: 5 }, () => ({
      productId: 0,
      productNo: "",
      productName: "",
      quantity: "",
      uom: "",
      notes: "",
      isOtherUOM: false,
      specialComments: "",
    }))
  );
  
  

  const [formData, setFormData] = useState({
    customerName: { value: 0, text: '' }, 
    deliveryDate: "",
  });
    const [customerList, setCustomerList] = useState([]);
  const [productList, setProductList] = useState([]);

  const [errors, setErrors] = useState({});

  // Fetch buyer list from API
  useEffect(() => {
    const fetchBuyerList = async () => {
      try {
        const res = await buildGetData(getFullURL(API_PATH.SALES_CREATE_URL));
        if (res?.data?.status === "success") {
          const data = res.data.result;
          setCustomerList(data.customerList);
          setProductList(data.productList);
        } else {
          alert("Failed to fetch buyer list");
        }
      } catch (error) {
        console.error("Error fetching buyer list:", error);
      }
    };

    fetchBuyerList();
  }, []);


  const validateItems = () => {
    const errors = selectedItems.map(item => {
      const itemError = {};
  
      
      // Validate product no
      if (item.productNo === "") {
        itemError.productName = "Invalid Product Name";
      }

      // Validate product name
      if (!item.productName || item.productName.trim() === "") {
        itemError.productName = "Product Name is required";
      }
  
      // Validate quantity
      if (!item.quantity || parseFloat(item.quantity) <= 0) {
        itemError.quantity = "Quantity is required and must be greater than 0";
      }
  
      // Validate UOM
      if (!item.uom || item.uom.trim() === "") {
        itemError.uom = "UOM is required";
      } else if (item.uom === "Other" && (!item.isOtherUOM || item.notes.trim() === "")) {
        itemError.uom = "Please Enter notes";
      }
  
      return Object.keys(itemError).length > 0 ? itemError : null;
    });
  
    return errors;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset previous errors
    setItemsError("");
    
    // Validate buyer
    let isValid = true;
  
      const newErrors = {};
      if (!formData.customerName.text){
        newErrors.customerName = "Customer Name is required.";
        isValid = false;
      }
      if (!formData.deliveryDate){
         newErrors.deliveryDate = "Delivery Date is required.";
         isValid = false;
      }
      setErrors(newErrors);
    
    // Validate items
    if (selectedItems.length === 0) {
      setItemsError("Please add at least one item");
      isValid = false;
    }
    
    // Validate item details
    const itemErrors = validateItems();
    const hasItemErrors = itemErrors.some(error => error !== null);
    
    if (hasItemErrors) {
      setItemValidationErrors(itemErrors);
      isValid = false;
    }
    
    // If all validations pass
    if (isValid) {
      const date = new Date(formData.deliveryDate);
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${
        date.getDate().toString().padStart(2, '0')
      }/${date.getFullYear()}`;
      
        const salesData = {
            salesOrderId: 0,
            customerName: formData.customerName.text,
            customerId: formData.customerName.value,
            deliveryDate: formattedDate,
            comments: "",
            status: 0,
            lineItems: selectedItems.map(item => ({
              lineItemId: 0,
              productId: item.productId,
              productName: item.productName,
              productNo: item.productNo,
              qty: item.quantity,
              uom: item.uom,
              notes: item.notes,
              specialComments: item.specialComments
            }))
        };
        

        try {
          const res = await buildPostData(getFullURL(API_PATH.SALES_SAVE_URL), salesData);
          if (res?.data?.status === "success") {
            setShowModal(true); 
          } 
        } catch (error) {
          console.error("Error fetching buyer list:", error);
        } finally {
          setIsLoading(false);
        }
    }
  };
  const addEmptyItem = () => {
    setSelectedItems([
      ...selectedItems,
      {
        productId: 0,
        productNo: "",
        productName: "",
        quantity: "",
        uom: "",
        notes: "",
        isOtherUOM: false,
        specialComments: "",
      },
    ]);
  };


  const updateFieldValue = (index, field, value) => {
    const updatedItems = [...selectedItems];
    
    if (field === "uom") {
      if (updatedItems[index].isOtherUOM) {
        updatedItems[index].uom = value;
      } else if (value === "Other") {
        updatedItems[index].isOtherUOM = true;
        updatedItems[index].uom = value;
      } else {
        updatedItems[index].isOtherUOM = false;
        updatedItems[index].uom = value;
      }
    } else if (field === "productName") {
      const matchedProduct = productList.find(
        (product) => product.text1 === value
      );
  
      if (matchedProduct) {
        // If product matches, update the selected item's details
        updatedItems[index].productId = matchedProduct.value;
        updatedItems[index].productNo = matchedProduct.text;
        updatedItems[index].productName = matchedProduct.text1;
      } else {
        // If product does not match, show an error or clear the field
        updatedItems[index].productId = 0;
        updatedItems[index].productNo = "";
        updatedItems[index].productName = value; // Keep the user input
        // Optionally set an error for invalid product name
        const newErrors = [...itemValidationErrors];
        newErrors[index] = { ...newErrors[index], productName: "Invalid Product Name" };
        setItemValidationErrors(newErrors);
      }
    }
    /*else if (field === "productNo") {
      const selectedProduct = productList.find(product => product.text === value);
      
      if (selectedProduct) {
        updatedItems[index].productId = selectedProduct.value;
        updatedItems[index].productNo = selectedProduct.text;
        updatedItems[index].productName = selectedProduct.text1;
      } else {
        updatedItems[index].productId = 0; 
        updatedItems[index].productNo = value; 
      }
    }*/
    
    else {
      updatedItems[index][field] = value;
    }

    if (itemValidationErrors.length > 0) {
      const newErrors = [...itemValidationErrors];
      newErrors[index] = { ...newErrors[index], [field]: "" };
      setItemValidationErrors(newErrors);
    }
  
    setSelectedItems(updatedItems);
  };
  

  const removeItem = (index) => {
    if (selectedItems.length === 1) {
      alert("At least one item must be present.");
      return;
    }
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };
  

  const handleCustomerChange = (e) => {
    const customerName = e.target.value;

    const selectedCustomer = customerList.find(
      (customer) => customer.text === customerName
    );

    setFormData({
      ...formData,
      customerName: selectedCustomer
        ? { value: selectedCustomer.value, text: selectedCustomer.text }
        : { value: 0, text: customerName },
    });
  };

  return (
    <div className="create-purchase">
      <form onSubmit={handleSubmit} className="purchase-form">
        <h2>Create Orders</h2>

      {/* Customer Name */}
      <div className="form-row">
        <div className="form-group buyer-section">
          <label>
          Restaurant/Customer Name<span className="required">*</span>
          </label>
          <input
            type="text"
            name="customerName"
            value={formData.customerName.text}
            onChange={handleCustomerChange}
            placeholder="Enter or select customer name"
            list="customerList"
          />
          <datalist id="customerList">
            {customerList.map((customer, index) => (
              <option key={index} value={customer.text} />
            ))}
          </datalist>
          {errors.customerName && <span className="error-message">{errors.customerName}</span>}
        </div>

        {/* Delivery Date */}
        <div className="form-group" style={{ marginLeft: 60 }}>
            <label>
              Delivery Date<span className="required">*</span>
            </label>
            <DatePicker
              selected={formData.deliveryDate}
              onChange={(date) =>
                setFormData((prevData) => ({ ...prevData, deliveryDate: date }))
              }
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
            />
            {errors.deliveryDate && (
              <span className="error-message">{errors.deliveryDate}</span>
            )}
          </div>
        </div>
       

        {/* Item Table */}
        <div>
        <h2>Line Items</h2>
          <table className="item-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>UOM</th>
              <th>Notes</th>
              {/*<th>Special Comments</th>*/}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedItems.map((item, index) => {
              const itemError = itemValidationErrors[index] || {};
            return(
              <tr key={index}>
                <td>
                    <input
                      type="text"
                      name="productName"
                      value={item.productName}
                      onChange={(e) =>
                        updateFieldValue(index, "productName", e.target.value)
                      }
                      placeholder="Enter or select Product Name"
                      list="productList"
                    />
                    <datalist id="productList">
                      {productList.map((product, i) => (
                        <option key={i} value={product.text1}>{product.text1}</option>
                      ))}
                    </datalist>
                {itemError.productName && (
                  <div className="field-error">{itemError.productName}</div>
                )}
                </td>
                <td>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      updateFieldValue(index, "quantity", e.target.value)
                    }
                    placeholder="Enter quantity"
                  />
                {itemError.quantity && (
                  <div className="field-error">{itemError.quantity}</div>
                )}
                </td>
                <td>
                    <select
                      value={item.uom}
                      onChange={(e) =>
                        updateFieldValue(index, "uom", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Punnet">Punnet</option>
                      <option value="Tray">Tray</option>
                      <option value="Bag">Bag</option>
                      <option value="Box">Box</option>
                      <option value="Kg">Kg</option>
                      <option value="Bunch">Bunch</option>
                      <option value="Pcs">Pcs</option>
                      <option value="pocket">pocket</option>
                      <option value="Other">Other</option>
                    </select>
                {itemError.uom && (
                  <div className="field-error">{itemError.uom}</div>
                )}
                </td>
                <td>
                  <input
                    type="text"
                    value={item.notes}
                    onChange={(e) => updateFieldValue(index, "notes", e.target.value)}
                    placeholder="Enter notes"
                  />
                </td>
                {/*<td>
                  <input
                    type="text"
                    value={item.specialComments}
                    onChange={(e) =>
                      updateFieldValue(index, "specialComments", e.target.value)
                    }
                    placeholder="Enter special comments"
                  />
                </td>*/}
                <td>
                  <button
                    type="button"
                    onClick={() => removeItem(index)}
                    className="remove-item-btn"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            )}
            )}
            {selectedItems.length === 0 && (
              <tr>
                <td colSpan="5">No Products added</td>
              </tr>
            )}
          </tbody>
        </table>
        {itemsError && <div className="error-message">{itemsError}</div>}
        <div>
            <button type="button" onClick={addEmptyItem} className="add-item-btn">
              Add Products
            </button>
          </div>

         
        </div>
          
        {/* Buttons */}
        <div className="form-footer">
          <button type="button" onClick={() => navigate(-1)} className="back-btn">
            Back
          </button>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>

     
      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Order Saved/Updated Successfully</p>
            <button className="modal-btn" onClick={() => {
              setShowModal(false); 
              navigate(-1); 
            }}>
            Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSalesOrder;