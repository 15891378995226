import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "./CreateSalesOrder.css";
import "react-datepicker/dist/react-datepicker.css";
import { buildGetData, getFullURL } from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { FaDownload, FaArrowLeft  } from "react-icons/fa";

const PurchaseOrderReport = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const [formData, setFormData] = useState({ deliveryDate: "", orderType: true });
  const [productList, setProductList] = useState([]);
  const [previousProductList, setPreviousProductList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isProductsVisible, setIsProductsVisible] = useState(false);

  const fetchLineItems = async (deliveryDate, orderType) => {
    setErrors({});
    setProductList([]);
    //const newErrors = {};
    //if (!formData.deliveryDate) {
    //  newErrors.deliveryDate = "Delivery Date is required.";
    //  setErrors(newErrors);
    //  return;
    //s}
    if(deliveryDate === ''){
      return;
    }
    try {
      setIsLoading(true);
      const date = new Date(deliveryDate);
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
      const poType = orderType ? 'Group' : ''; 
      const res = await buildGetData(getFullURL(`/sales/grouporder?deliveryDate=${formattedDate}&poOrderType=${poType}`));

      if (res?.data?.status === "success") {
        const data = res.data.result;
        setProductList(data?.rows);
        setPreviousProductList(data?.rows1 ?? []);
        setIsProductsVisible(true); 
      } else {
        alert("Failed to fetch buyer list");
        setIsProductsVisible(false); // Hide table if no data
      }
    } catch (error) {
      console.error("Error fetching buyer list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Fetch the date from the delivery date form field
    const date = new Date(formData.deliveryDate);
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
  
    // Set header text
    doc.setFont("times", "bold");
    doc.setFontSize(16);
    const headerText = `Purchase Orders Report`;
    const pageWidth = doc.internal.pageSize.getWidth();
    const headerWidth = doc.getTextWidth(headerText);
    doc.text(headerText, (pageWidth - headerWidth) / 2, 20);
  
    // Delivery Date text
    doc.setFontSize(12);
    const deliveryDateText = `Delivery Date: ${formattedDate}`;
    const deliveryDateWidth = doc.getTextWidth(deliveryDateText);
    doc.text(deliveryDateText, (pageWidth - deliveryDateWidth) / 2, 30);
  
    // Add "Products" title
    doc.setFontSize(14);
    const heading = !formData.orderType ? "Current PO:" : "Purchase Order:"
    doc.text(heading, 15, 40);
  
    // Check if productList is empty
    if (productList.length === 0) {
      doc.autoTable({
        head: [["Product Name", "Quantity", "UOM"]],
        body: [["No Products To Display", "", ""]],
        startY: 45, // Start below the "Products" title
        theme: "grid",
        styles: {
          halign: "center", // Center align the text
        },
        headStyles: {
          fillColor: [40, 167, 69], // Green color for the header
          textColor: [255, 255, 255], // White text
        },
        bodyStyles: {
          textColor: [255, 0, 0], // Red text for "No Products To Display"
        },
        margin: { top: 10, left: 15, right: 15 },
      });
    } else {
      // Prepare the first table headers and data
      const tableHeaders = [["Product Name", "Quantity", "UOM", "Comments"]];
      const tableData = productList.map((item) => [
        item.productName,
        item.qty.toString(),
        item.uom,
        item.comments,
      ]);
  
      // Use jspdf-autotable for the first table
      doc.autoTable({
        head: tableHeaders,
        body: tableData,
        startY: 45, // Start below the "Products" title
        theme: "grid",
        styles: {
          halign: "center", // Center align the text
        },
        headStyles: {
          fillColor: [40, 167, 69], // Green color for the header
          textColor: [255, 255, 255], // White text
        },
        margin: { top: 10, left: 15, right: 15 },
      });
    }
  
    // Conditionally add the second table if the checkbox is unchecked
    if (!formData.orderType) {
      const startY = doc.lastAutoTable.finalY + 10; // Position the second table below the first one
  
      // Add "Previous Products" title
      doc.setFontSize(14);
      doc.text("Previous PO:", 15, startY);
  
      // Check if previousProductList is empty
      if (previousProductList.length === 0) {
        doc.autoTable({
          head: [["Product Name", "Quantity", "UOM", "Comments"]],
          body: [["No Previous Products To Display", "", ""]],
          startY: startY + 5, // Start below the "Previous Products" title
          theme: "grid",
          styles: {
            halign: "center", // Center align the text
          },
          headStyles: {
            fillColor: [40, 167, 69], // Green color for the header
            textColor: [255, 255, 255], // White text
          },
          bodyStyles: {
            textColor: [255, 0, 0], // Red text for "No Previous Products To Display"
          },
          margin: { top: 10, left: 15, right: 15 },
        });
      } else {
        const previousTableData = previousProductList.map((item) => [
          item.productName,
          item.qty.toString(),
          item.uom,
          item.comments
        ]);
  
        doc.autoTable({
          head: [["Product Name", "Quantity", "UOM", "Comments"]],
          body: previousTableData,
          startY: startY + 5, // Start below the "Previous Products" title
          theme: "grid",
          styles: {
            halign: "center", // Center align the text
          },
          headStyles: {
            fillColor: [40, 167, 69], // Green color for the header
            textColor: [255, 255, 255], // White text
          },
          margin: { top: 10, left: 15, right: 15 },
        });
      }
    }
  
    // Save the PDF
    doc.save(`purchase-orders-report-${formattedDate}.pdf`);
  };
  
  
  
  
  const handleDateChange = (date) => {
    setFormData((prevData) => ({ ...prevData, deliveryDate: date }));
    setErrors({});
    fetchLineItems(date, formData.orderType);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setFormData((prevData) => ({ ...prevData, orderType: isChecked }));
    fetchLineItems(formData.deliveryDate, isChecked);
  };
  

  return (
    <div className="create-purchase">
      <form className="purchase-form">
        <h2>Purchase Orders Report</h2>

        <div className="form-row">
          {/* Delivery Date */}
          <div className="form-group">
            <label>
              Delivery Date<span className="required">*</span>
            </label>
            <DatePicker
              selected={formData.deliveryDate}
              onChange={handleDateChange}
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
            />
            {errors.deliveryDate && (
              <span className="error-message">{errors.deliveryDate}</span>
            )}
          </div>

          {/* Po Order Type */}
          <div className="form-group" style={{ marginLeft: "20px", marginTop: "10px" }}>
            <label>
              <input
                type="checkbox"
                checked={formData.orderType}
                onChange={handleCheckboxChange}
              />
              Group Order
            </label>
          </div>
          
          {isProductsVisible && (
          <div>
            <button
              type="button"
              onClick={generatePDF}
              style={{ marginTop: 20, marginLeft:50, backgroundColor: `#28a745` }}
              className="add-item-btn"
            >
               <FaDownload style={{ marginRight: 8 }} />  Print
            </button>
          </div>)}
        </div>

        {/* Item Table */}
        {isProductsVisible && (
          <div>
          <h2>{!formData.orderType ? "Current PO" : "Purchase Order"}</h2>
            <table className="item-table" id="product-table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>UOM</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {productList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.productName}</td>
                    <td>{item.qty}</td>
                    <td>{item.uom}</td>
                    <td>{item.comments}</td>
                  </tr>
                ))}
                {productList.length === 0 && (
                  <tr>
                    <td colSpan="4">No Products To Display</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {isProductsVisible && !formData.orderType && (
          <div>
            <h2>Previous PO </h2>
            <table className="item-table" id="product-table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>UOM</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {previousProductList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.productName}</td>
                    <td>{item.qty}</td>
                    <td>{item.uom}</td>
                    <td>{item.comments}</td>
                  </tr>
                ))}
                {previousProductList.length === 0 && (
                  <tr>
                    <td colSpan="4">No Previous Products To Display</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {/* Buttons */}
        <div className="form-footer">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="back-btn"
          >
              <FaArrowLeft style={{ marginRight: 8 }} /> Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default PurchaseOrderReport;
