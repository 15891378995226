import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam"; 
import "./CreatePurchase.css";
import ProductsItemModal from "./ProductsItemModal";
import { buildGetData, buildUploadFile, getFullURL, API_PATH } from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";
import { FaCamera, FaTimes } from "react-icons/fa";

const CreatePurchase = () => {
  const [buyerList, setBuyerList] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [capturedImages, setCapturedImages] = useState([{ name : ""}]);
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const { setIsLoading } = useLoading(); 
  const [cameraError, setCameraError] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // Validation state
  const [buyerError, setBuyerError] = useState("");
  const [itemsError, setItemsError] = useState("");
  const [itemValidationErrors, setItemValidationErrors] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); 

  // Fetch buyer list from API
  useEffect(() => {
    const fetchBuyerList = async () => {
      try {
        const res = await buildGetData(getFullURL(API_PATH.PURCHASE_CREATE_URL));
        if (res?.data?.status === "success") {
          setBuyerList(res.data.result.buyerList);
        } else {
          alert("Failed to fetch buyer list");
        }
      } catch (error) {
        console.error("Error fetching buyer list:", error);
      }
    };

    fetchBuyerList();
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const addItems = (items) => {
    const newItems = items.map((item) => ({
      ...item,
      quantity: "", 
      unitPrice: "", 
      radioValue: "",
      vendorName: "",
      dropdownValue: "",
      total: 0,
      uom: "",
    }));
    setSelectedItems([...selectedItems, ...newItems]);
    closeModal();
  };

  const removeItem = (index) => {
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  const updateFieldValue = (index, field, value) => {
    const updatedItems = [...selectedItems];
    updatedItems[index][field] = value;
    
    // Clear specific field error when user starts typing/selecting
    if (itemValidationErrors.length > 0) {
      const newErrors = [...itemValidationErrors];
      newErrors[index] = { ...newErrors[index], [field]: "" };
      setItemValidationErrors(newErrors);
    }
    
    setSelectedItems(updatedItems);
  };

  const validateItems = () => {
    const errors = selectedItems.map(item => {
      const itemError = {};
      
      if (!item.quantity || parseFloat(item.quantity) <= 0) {
        itemError.quantity = "Quantity is required and must be greater than 0";
      }
      
      if (!item.unitPrice || parseFloat(item.unitPrice) < 0) {
        itemError.unitPrice = "Unit Price is required and must be non-negative";
      }
      
      if (!item.dropdownValue) {
        itemError.dropdownValue = "Please select an option";
      }
      
      if (!item.vendorName) {
        itemError.vendorName = "Vendor Name is required";
      }
      
      if (!item.radioValue) {
        itemError.radioValue = "Please select Payment Type";
      }
      
      return Object.keys(itemError).length > 0 ? itemError : null;
    });
    
    return errors;
  };

  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset previous errors
    setBuyerError("");
    setItemsError("");
    
    // Validate buyer
    let isValid = true;
    if (!selectedBuyer) {
      setBuyerError("Buyer is required");
      isValid = false;
    }
    
    // Validate items
    if (selectedItems.length === 0) {
      setItemsError("Please add at least one item");
      isValid = false;
    }
    
    // Validate item details
    const itemErrors = validateItems();
    const hasItemErrors = itemErrors.some(error => error !== null);
    
    if (hasItemErrors) {
      setItemValidationErrors(itemErrors);
      isValid = false;
    }
    
    // If all validations pass
    if (isValid) {
        const purchaseOrderData = {
            purchaseOrderId: 0,
            buyerId: parseInt(selectedBuyer), 
            status: 0,
            creditAmount: 0, 
            debitAmount: 0, 
            totalAmount: 0, 
            lineItems: selectedItems.map(item => ({
              lineItemId: 0, 
              vendorName: item.vendorName,
              qty: parseFloat(item.quantity),
              productId: item.productId,
              uom: item.dropdownValue, 
              price: parseFloat(item.unitPrice),
              netPrice: parseFloat(item.quantity) * parseFloat(item.unitPrice),
              taxAmount: 0, 
              totalAmount: parseFloat(item.quantity) * parseFloat(item.unitPrice),
              credit: item.radioValue === "Credit" 
            }))
        };
        purchaseOrderData.creditAmount = purchaseOrderData.lineItems
            .filter(item => item.credit)
            .reduce((sum, item) => sum + item.totalAmount, 0);
      
        purchaseOrderData.debitAmount = purchaseOrderData.lineItems
            .filter(item => !item.credit)
            .reduce((sum, item) => sum + item.totalAmount, 0);
        
        purchaseOrderData.totalAmount = purchaseOrderData.creditAmount + purchaseOrderData.debitAmount;

        // Create FormData instance
        const formData = new FormData();

        // Append the purchaseOrderData to the formData as key-value pairs
        formData.append("purchaseOrderId", purchaseOrderData.purchaseOrderId);
        formData.append("buyerId", purchaseOrderData.buyerId);
        formData.append("status", purchaseOrderData.status);
        formData.append("creditAmount", purchaseOrderData.creditAmount);
        formData.append("debitAmount", purchaseOrderData.debitAmount);
        formData.append("totalAmount", purchaseOrderData.totalAmount);

        // Loop through the line items array and append each item to formData
        purchaseOrderData.lineItems.forEach((item, index) => {
            formData.append(`lineItems[${index}].lineItemId`, item.lineItemId);
            formData.append(`lineItems[${index}].vendorName`, item.vendorName);
            formData.append(`lineItems[${index}].qty`, item.qty);
            formData.append(`lineItems[${index}].productId`, item.productId);
            formData.append(`lineItems[${index}].uom`, item.uom);
            formData.append(`lineItems[${index}].price`, item.price);
            formData.append(`lineItems[${index}].netPrice`, item.netPrice);
            formData.append(`lineItems[${index}].taxAmount`, item.taxAmount);
            formData.append(`lineItems[${index}].totalAmount`, item.totalAmount);
            formData.append(`lineItems[${index}].credit`, item.credit);
        });

        capturedImages.forEach((item, index) => {
          if (item.name !== "") {
              const byteString = atob(item.uri.split(",")[1]); // Decode base64
              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                  ia[i] = byteString.charCodeAt(i);
              }
              const blob = new Blob([ia], { type: item.type });

              // Extract name safely
              const imageName = (item?.name?.lastIndexOf("/") >= 0)
                  ? item?.name.slice(item?.name.lastIndexOf("/") + 1)
                  : item?.name || `image_${Date.now()}.jpg`;
      
              const file = new File([blob], imageName, { type: item.type });
      
              formData.append(`attachments[${index}]`, file); // Append as a file
          }
        });

        try {
          const res = await buildUploadFile(getFullURL(API_PATH.PURCHASE_SAVE_URL), formData);
          if (res?.data?.status === "success") {
            setShowModal(true); 
          } 
        } catch (error) {
          console.error("Error fetching buyer list:", error);
        } finally {
          setIsLoading(false);
        }
    }
  };

  const calculateTotalPrice = (quantity, unitPrice) => {
    if (quantity && unitPrice) {
      return (parseFloat(quantity) * parseFloat(unitPrice)).toFixed(2);
    }
    return 0;
  };

  const calculateAmounts = () => {
    let debitAmount = 0;
    let creditAmount = 0;
    
    selectedItems.forEach(item => {
      const totalPrice = parseFloat(calculateTotalPrice(item.quantity, item.unitPrice));
      if (item.radioValue === "Debit") {
        debitAmount += totalPrice;
      } else if (item.radioValue === "Credit") {
        creditAmount += totalPrice;
      }
    });
    
    const grandTotal = debitAmount + creditAmount;

    return { debitAmount, creditAmount, grandTotal };
  };

  const { debitAmount, creditAmount, grandTotal } = calculateAmounts();

   // Function to handle adding a new image input field
   const handleAddImage = () => {
    setCapturedImages([...capturedImages, { name: "" }]);
  };

  const openCamera = (index) => {
    setSelectedImageIndex(index); // Set the index of the image being captured
    setIsCameraOpen(true);  // Open the camera modal
    setCameraError(null);   // Reset any previous camera errors
  };

  const closeCamera = () => {
    setIsCameraOpen(false); // Close camera modal
    setSelectedImageIndex(null); // Reset the selected index
  };
  
  // Cancel/remove captured image
  const removeImage = (index) => {
    if(capturedImages.length > 1) {
      setCapturedImages(capturedImages.filter((_, i) => i !== index));
    }
  };
  
  const captureImage = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot(); // Capture the image from webcam
      if (imageSrc) {
        // Convert base64 image to a Blob
        const byteString = atob(imageSrc.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: "image/jpeg" }); // Blob for image size
        const imageName = `image_${Date.now()}.jpg`;
  
        const imageData = {
          name: imageName,
          type: blob.type,
          uri: imageSrc,
          size: blob.size,
        };
  
        // Update captured images at the selected index
        const updatedImages = [...capturedImages];
        updatedImages[selectedImageIndex] = imageData;
  
        setCapturedImages(updatedImages); // Save the updated image
        closeCamera(); // Close the camera modal after capturing
      } else {
        console.error("Failed to capture image");
        setCameraError("Unable to capture image. Please try again.");
      }
    } else {
      console.error("Webcam ref is not available");
      setCameraError("Webcam is not initialized");
    }
  }, [capturedImages, selectedImageIndex, webcamRef]);
  
  
  // Error handling for camera access
  const handleCameraError = useCallback((error) => {
    console.error("Error accessing camera:", error);
    setCameraError("Camera access is denied or unavailable. Please check permissions.");
  }, []);

  return (
    <div className="create-purchase">
      <form onSubmit={handleSubmit} className="purchase-form">
        <h2>Create Purchase</h2>

        {/* Buyer Dropdown */}
        <div className="buyer-section">
          <label>
            Buyer <span className="required-asterisk">*</span>
          </label>
          <select
            value={selectedBuyer}
            onChange={(e) => {
              setSelectedBuyer(e.target.value);
              setBuyerError(""); // Clear error when selecting
            }}
            className="buyer-dropdown"
          >
            <option value="">Select Buyer</option>
            {buyerList.map((buyer) => (
              <option key={buyer.value} value={buyer.value}>
                {buyer.text}
              </option>
            ))}
          </select>
          {buyerError && <div className="error-message">{buyerError}</div>}
        </div>

        {/* Item Table */}
        <div>
          <div>
            <button type="button" onClick={openModal} className="add-item-btn">
              Add Products
            </button>
          </div>

          {itemsError && <div className="error-message">{itemsError}</div>}

          <table className="item-table">
            <thead>
              <tr>
                <th>Product No</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>UOM</th>
                <th>Unit Price</th>
                <th>Total Price</th>
                <th>Vendor Name</th>
                <th>Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedItems.map((item, index) => {
                const itemError = itemValidationErrors[index] || {};
                return (
                  <tr key={index}>
                    <td>{item.productNo}</td>
                    <td>{item.productName}</td>
                    <td>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          updateFieldValue(index, "quantity", e.target.value)
                        }
                      />
                      {itemError.quantity && (
                        <div className="field-error">{itemError.quantity}</div>
                      )}
                    </td>
                    <td>
                      <select
                        value={item.dropdownValue}
                        onChange={(e) =>
                          updateFieldValue(index, "dropdownValue", e.target.value)
                        }
                      >
                        <option value="">Select</option>
                        <option value="Punnet">Punnet</option>
                        <option value="Tray">Tray</option>
                        <option value="Bag">Bag</option>
                        <option value="Box">Box</option>
                        <option value="Kg">Kg</option>
                        <option value="Bunch">Bunch</option>
                        <option value="Pcs">Pcs</option>
                        <option value="pocket">pocket</option>
                        <option value="Other">Other</option>

                      </select>
                      {itemError.dropdownValue && (
                        <div className="field-error">{itemError.dropdownValue}</div>
                      )}
                    </td>
                    <td>
                      <input
                        type="number"
                        value={item.unitPrice}
                        onChange={(e) =>
                          updateFieldValue(index, "unitPrice", e.target.value)
                        }
                      />
                      {itemError.unitPrice && (
                        <div className="field-error">{itemError.unitPrice}</div>
                      )}
                    </td>
                    <td>
                      <span>{calculateTotalPrice(item.quantity, item.unitPrice)}</span>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.vendorName}
                        onChange={(e) =>
                          updateFieldValue(index, "vendorName", e.target.value)
                        }
                      />
                      {itemError.vendorName && (
                        <div className="field-error">{itemError.vendorName}</div>
                      )}
                    </td>
                    <td className="radio-cell">
                      <div>
                        <input
                          type="radio"
                          id={`credit-${index}`}
                          name={`radio-${index}`}
                          value="Credit"
                          checked={item.radioValue === "Credit"}
                          onChange={(e) =>
                            updateFieldValue(index, "radioValue", e.target.value)
                          }
                        />
                        <label htmlFor={`credit-${index}`}>Credit</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id={`debit-${index}`}
                          name={`radio-${index}`}
                          value="Debit"
                          checked={item.radioValue === "Debit"}
                          onChange={(e) =>
                            updateFieldValue(index, "radioValue", e.target.value)
                          }
                        />
                        <label htmlFor={`debit-${index}`}>Debit</label>
                      </div>
                      {itemError.radioValue && (
                        <div className="field-error">{itemError.radioValue}</div>
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => removeItem(index)}
                        className="remove-item-btn"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                );  
              })}
              {selectedItems.length === 0 && (
                <tr>
                  <td colSpan="9">No products selected</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
          
          <div className="buyer-section" style={{ marginTop: "10px" }}>
      <label>Attachments</label>
      {capturedImages.map((img, index) => (
        <div
          key={index}
          style={{
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            name="productName"
            value={img.name}
            disabled
            style={{
              marginRight: "20px",
              padding: "8px",
              border: "1px solid #ddd",
              borderRadius: "4px",
            }}
            placeholder={`Attachment (${index+1})`}
          />
            
            <FaCamera
              onClick={() => openCamera(index)}
              style={{
                cursor: "pointer",
                color: "#4CAF50", // Green color for camera icon
                marginRight: "10px"
              }}
            />
            <FaTimes
              onClick={() => removeImage(index)}
              style={{
                cursor: "pointer",
                color: "#f44336", // Red color for cancel icon
              }}
            />
        </div>
      ))}
      <div>
        <span
          onClick={handleAddImage}
          style={{
            color: "#007BFF", // Blue text color
            cursor: "pointer", // Make the text clickable
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            fontSize: "14px",
          }}
        >
          + Add Attachment
        </span>
      </div>
    </div>

    {/* Camera Modal */}
    {isCameraOpen && (
      <div className="modal">
      <div className="modal-content">
          <div className="webcam-view">
            {cameraError ? (
              <div className="camera-error">
                <p>{cameraError}</p>
                <button
                  type="button"
                  onClick={() => setCameraError(null)}
                  className="retry-btn"
                >
                  Retry
                </button>
              </div>
            ) : (
              <Webcam
                audio={false}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",
                  width: { ideal: 770 },
                  height: { ideal: 500 },
                }}
                ref={webcamRef}
                onUserMediaError={handleCameraError}
              />
            )}
          </div>

          <div className="camera-controls">
            <button
              type="button"
              onClick={captureImage}
              className="camera-capture-btn"
              disabled={cameraError !== null}
            >
              Capture Image
            </button>
            <button
              type="button"
              onClick={closeCamera}
              className="camera-close-btn"
            >
              Close Camera
            </button>
          </div>
        </div>
      </div>
    )}
        {/* Summary Section */}
        <div className="summary-section" >
        <div className="items-header">
              <label>Summary</label>
            </div>
        
          <div className="summary-item">
            <label>Debit Amount:</label>
            <span>{debitAmount.toFixed(2)}</span>
          </div>
          <div className="summary-item">
            <label>Credit Amount:</label>
            <span>{creditAmount.toFixed(2)}</span>
          </div>
          <div className="summary-item">
            <label>Grand Total:</label>
            <span>{grandTotal.toFixed(2)}</span>
          </div>
        </div>

        {/* Buttons */}
        <div className="form-footer">
          <button type="button" onClick={() => navigate(-1)} className="back-btn">
            Back
          </button>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>

      {/* Modal for selecting items */}
      {isModalOpen && (
        <ProductsItemModal onClose={closeModal} onAddItems={addItems} />
      )}
      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Purchase Saved/Updated Successfully</p>
            <button className="modal-btn" onClick={() => {
              setShowModal(false); 
              navigate(-1); 
            }}>
            Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePurchase;