import React, { useEffect, useState, useCallback } from "react";
import "../../Home.css";
import Grid from "../../../constants/Grid";
import { buildGetData, getFullURL, API_PATH } from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

const SalesOrderList = () => {
  const [items, setItems] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await buildGetData(getFullURL(API_PATH.SALES_LIST_URL));
      if (res?.data?.status === "success") {
        setItems(res.data.result.rows);
        setIsLoading(false);
      } else {
        console.error("Unexpected response:", res);
        alert("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    init();
  }, [init]);

  const fetchLineItems = async (item) => {
    setIsLoading(true);
    try {
      const res = await buildGetData(getFullURL(`/sales/${item.salesOrderId}/orderitems`));
      if (res?.data?.status === "success") {
        setLineItems(res.data.result.rows);
        setIsModalOpen(true); // Open the modal after data fetch
        setIsLoading(false);
      } else {
        console.error("Unexpected response:", res);
        alert("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const columnData = [
    { headerName: "Restaurant/Customer Name", field: "customerName" },
    { headerName: "Sales Order No.", field: "salesOrderNo" },
    { headerName: "Delivery date", field: "deliveryDate" },
  ];

  const searchFields = ["salesOrderNo", "customerName"];

  const processedItems = items.map((item) => ({
    ...item,
    status: item.status === 0 ? "Active" : "Inactive",
  }));

  const handleAction = (actionType, rowData) => {
    if (actionType === "customerName") {
      setSelectedItem(rowData)
      fetchLineItems(rowData);
    }
  };

  const actions = [
    {
      name: "Edit",
      icon: <FaEdit />,
      color: "#0096FF",
      callback: (rowData) =>  navigate(`/EditSalesOrder/${rowData.salesOrderId}`),
    },
  ];

  return (
    <div className="home">
      {/* Create Button Positioned Above the Grid */}
      <div style={{ marginBottom: "10px", textAlign: "right" }}>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "5px",
            marginRight: "11px",
          }}
          onClick={() => navigate("/PurchaseOrderReport")}
        >
         Purchase Order
        </button>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "5px",
            marginRight: "11px",
          }}
          onClick={() => navigate("/CustomerOrderReport")}
        >
         Customer orders
        </button>
        <button
          style={{
            padding: "8px 16px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            marginTop: "5px",
            marginRight: "11px",
          }}
          onClick={() => navigate("/CreateSalesOrder")}
        >
          Create
        </button>
      </div>

      <Grid
        rowData={processedItems}
        columnData={columnData}
        searchFields={searchFields}
        excelName="Sales"
        actions={actions}
        clickableFields={[{ field: "customerName", type: "customerName" }]}
        onFieldClick={(field, rowData) => handleAction(field.field, rowData)}
      />

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "20px",
              width: "60%",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "column",
              maxHeight: "80%",
              overflow: "hidden",
            }}
          >
            {/* Modal Header */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <h2 style={{ margin: 0 }}>{`Customer Name: ${selectedItem.customerName}`}</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </div>

            {/* Modal Content: Table */}
            <div
              style={{
                marginTop: "20px",
                overflowY: "auto",
                maxHeight: "400px", // Table height limit with scroll
              }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ddd",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        backgroundColor: "#f0f0f0", // Gray header
                        textAlign: "left",
                        border: "1px solid #ddd", // Header border
                      }}
                    >
                      Product No
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        backgroundColor: "#f0f0f0",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      Product Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        backgroundColor: "#f0f0f0",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      Qty
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        backgroundColor: "#f0f0f0",
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      UOM
                    </th>
                    {lineItems.some(item => item.uom === "Other") && (
                      <th
                        style={{
                          padding: "10px",
                          backgroundColor: "#f0f0f0",
                          textAlign: "left",
                          border: "1px solid #ddd",
                        }}
                      >
                        Notes
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {lineItems.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: "10px",
                          border: "1px solid #ddd", // Cell border
                          textAlign: "left",
                        }}
                      >
                        {item.productNo}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          border: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        {item.productName}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          border: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        {item.qty}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          border: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        {item.uom}
                      </td>
                      {item.uom === "Other" && (
                        <td
                          style={{
                            padding: "10px",
                            border: "1px solid #ddd",
                            textAlign: "left",
                          }}
                        >
                          {item.notes}
                        </td>
                      )}          
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}



    </div>
  );
};

export default SalesOrderList;
