import React, { useEffect, useState, useCallback  } from "react";
import "./Home.css";
import Grid from "../constants/Grid";
import { buildGetData, getFullURL, API_PATH } from "../constants/constants";
import { useLoading } from "../constants/LoadingContext";
import { useNavigate } from "react-router-dom"; 

const Home = () => {
  const [items, setItems] = useState([]);
  const { setIsLoading } = useLoading(); 
  const navigate = useNavigate(); 

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await buildGetData(getFullURL(API_PATH.PURCHASE_LIST_URL));
      if (res?.data?.status === "success") {
        setItems(res.data.result.rows);
      } else {
        console.error("Unexpected response:", res);
        alert("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while fetching data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  
  useEffect(() => {
    init();
  }, [init]);

  const processedItems = items.map((item) => ({
    ...item,
    payment: item.credit === "Y" ? "Credit" : "Debit",
  }));

  const columnData = [
    { headerName: "Buyer Name", field: "buyerName" },
    { headerName: "PO #", field: "purchaseNo" },
    { headerName: "Purchase Date", field: "purchaseDate" },
    { headerName: "Product #", field: "productNo" },
    { headerName: "Product", field: "productName" },
    { headerName: "Qty", field: "qty" },
    { headerName: "UOM", field: "uom" },
    { headerName: "Unit Price", field: "unitPrice" },
    { headerName: "Total Amount", field: "totalAmount" },
    { headerName: "Vendor Name", field: "vendorName" },
    { headerName: "Payment", field: "payment" },
    { headerName: "Total Debit Amount", field: "totalDebitAmount" },
    { headerName: "Total Credit Amount", field: "totalCreditAmount" },
    { headerName: "Grand Total", field: "grandTotal" },
  ];

  const searchFields = ["buyerName","purchaseNo","productName","productNo","purchaseDate","vendorName"];

  return (
    <div>      
      {/* Create Button Positioned Above the Grid */}
      <div className="create-btn-container">
        <button
          className="create-btn"
          onClick={() => navigate("/createPurchase")}
        >
          Create
        </button>
      </div>

      {/* Grid Component */}
      <Grid rowData={processedItems} columnData={columnData} searchFields={searchFields} excelName="PurchaseOrder"/>
    </div>
  );
};

export default Home;
