import axios from "axios";

export const baseURL = "https://viowebs.in/VIAWEB/api";
export const baseURL1 = "http://localhost:8080/VIAWEB/api";

export const API_PATH = {
    LOGIN_URL: "/login",

    PRODUCTS_LIST_URL: "/products?search=",
    PRODUCTS_CREATE_URL: "/products/createform",
    PRODUCTS_EDIT_URL: "/products/1/editform",
    PRODUCTS_SAVE_URL: "/products/save",
    PRODUCT_DELETE_URL: "/products/{id}/deleteproduct",

    PURCHASE_LIST_URL: "/purchases?search=",
    PURCHASE_CREATE_URL: "/purchases/createform",
    PURCHASE_EDIT_URL: "/purchases/1/editform",
    PURCHASE_SAVE_URL: "/purchases/save",

    SALES_LIST_URL: "/sales?search=",
    SALES_CREATE_URL: "/sales/createform",
    SALES_EDIT_URL: "/sales/1/editform",
    SALES_SAVE_URL: "/sales/save",
    SALES_LINE_ITEMS_URL: "/sales/orderitems",
    PURCHASE_ORDER_REPORT_URL: "/sales/individualorder",
    SAVE_CUSTOMER_ORDER_FORM_URL: "/sales/savecustomerorder",
};

export const getFullURL = (path) => `${baseURL}${path}`;

export function PostData(url, data) {
    const request = {
        url,
        data,
        method: "POST",
        headers: { "content-type": "application/json" },
        timeout: 5000,
    };
    return axios(request);
}

export function buildGetData(url) {
    const accessToken = sessionStorage.getItem('accessToken'); 
    const request = {
        method: "GET",
        url: `${url}`,
        headers: { Authorization: `Bearer ${accessToken}` }, 
        timeout: 5000,
    };
    return axios(request);
}

export function buildPatchData(url) {
    const accessToken = sessionStorage.getItem('accessToken'); 
    const request = {
        method: "PATCH",
        url: `${url}`,
        headers: { Authorization: `Bearer ${accessToken}` }, 
        timeout: 10000,
    };
    return axios(request);
}

export function buildPostData(url, data) {
    const accessToken = sessionStorage.getItem('accessToken'); 
    const request = {
        method: "POST",
        url: `${url}`,
        headers: { Authorization: `Bearer ${accessToken}` }, 
        timeout: 10000,
        data,
    };
    return axios(request);
}

export function buildUploadFile(url, data) {
    const accessToken = sessionStorage.getItem('accessToken'); 
    const request = {
        method: "POST",
        url: `${url}`,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data; boundary=aebde4a4-aa99-49d2-8a0e-475be6b18394",
        },
        timeout: 5000,
        redirect: "follow",
        data,
    };
    return axios(request);
}