import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateProducts.css";
import { buildGetData, buildPostData, getFullURL, API_PATH } from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";

const CreateProducts = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading(); 
 // const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  // Fetch buyer list from API
  useEffect(() => {
    const fetchBuyerList = async () => {
      try {
        const res = await buildGetData(getFullURL(API_PATH.PRODUCTS_CREATE_URL));
        if (res?.data?.status === "success") {
          //setData(res.data.result);
        } else {
          alert("Failed to fetch buyer list");
        }
      } catch (error) {
        console.error("Error fetching buyer list:", error);
      }
    };

    fetchBuyerList();
  }, []);

  const [productData, setProductData] = useState({
    productName: "",
    productNo: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newErrors = {};
    if (name === 'productName') newErrors.productName = "";
    if (name === 'productNo') newErrors.productNo = "";
    setErrors(newErrors);
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!productData.productName) newErrors.productName = "Product Name is required.";
    if (!productData.productNo) newErrors.productNo = "Product Number is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      setIsLoading(true);
      const payload = {
        productName: productData.productName,
        productNo: productData.productNo,
        productId: 0, 
        status: 0,   
      };
      try {
        const res = await buildPostData(getFullURL(API_PATH.PRODUCTS_SAVE_URL), payload);
        if (res?.data?.status === "success") {
          setProductData({
            productName: "",
            productNo: "",
          });
          setErrors({});
          setShowModal(true); // Show the modal
          setTimeout(() => {
            setShowModal(false); // Hide the modal
            navigate(-1); // Navigate back
          }, 1500); // Delay of 1.5 seconds
        } 
      } catch (error) {
        console.error("Error fetching buyer list:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="create-purchase">
      <form onSubmit={handleSubmit} className="purchase-form">
        <h2>Create Products</h2>

        {/* Product Number */}
        <div className="form-group buyer-section">
          <label>
            Product No.<span className="required">*</span>
          </label>
          <input
            type="text"
            name="productNo"
            value={productData.productNo}
            onChange={handleInputChange}
            maxLength={50} 
            placeholder="Enter Product No." 
          />
          {errors.productNo && <span className="error-message">{errors.productNo}</span>}
        </div>

        {/* Product Name */}
        <div className="form-group buyer-section">
          <label>
            Product Name<span className="required">*</span>
          </label>
          <input
            type="text"
            name="productName"
            value={productData.productName}
            onChange={handleInputChange}
            maxLength={200} 
            placeholder="Enter Product Name" 
          />
          {errors.productName && <span className="error-message">{errors.productName}</span>}
        </div>

        {/* Buttons */}
        <div className="form-footer">
          <button type="button" onClick={() => navigate(-1)} className="back-btn">
            Back
          </button>
          <button type="submit" className="submit-btn">
            Submit
          </button>
        </div>
      </form>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Product Saved/Updated Successfully</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateProducts;
