import React from "react";
import "./modal.css";

const SettingsModal = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2>Settings</h2>
        <p>Here you can configure your settings.</p>
      </div>
    </div>
  );
};

export default SettingsModal;
