import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaFolder,
  FaHome,
  FaChevronLeft,
  FaChevronRight,
  FaCartPlus
} from "react-icons/fa";
import "./Sidebar.css";
import Logo from "../assets/logo.png"; // Import the logo image

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation(); // Get the current route

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      {/* Logo Section */}
      <div className="logo-section">
        <img
          src={Logo}
          alt="Logo"
          className={`logo ${isCollapsed ? "collapsed-logo" : ""}`}
        />
      </div>

      {/* Navigation Links */}
      <ul className="nav-links">
        <li className={location.pathname === "/products" ? "active" : ""}>
          <Link to="/items">
            <FaFolder className="icon" />
            {!isCollapsed && <span>Products</span>}
          </Link>
        </li>
        <li className={location.pathname === "/purchases" ? "active" : ""}>
          <Link to="/purchases">
            <FaHome className="icon" />
            {!isCollapsed && <span>Purchases</span>}
          </Link>
        </li>
        <li className={location.pathname === "/SalesOrder" ? "active" : ""}>
          <Link to="/SalesOrder">
            <FaCartPlus className="icon" />
            {!isCollapsed && <span>Orders</span>}
          </Link>
        </li>
      </ul>

      {/* Collapse Button */}
      <div className="toggle-btn" onClick={toggleSidebar}>
        {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
      </div>
    </div>
  );
};

export default Sidebar;
