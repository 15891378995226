import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { PostData, getFullURL, API_PATH } from "../constants/constants";
import { useLoading } from "../constants/LoadingContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const handleLogin = async () => {
    if (username && password) {
      setIsLoading(true);
      try {
        const url = getFullURL(API_PATH.LOGIN_URL);
        const data = {
          username: username,
          password: password,
        };

        const response = await PostData(url, data);
        if (response?.data?.status === "success") {
          const data = response?.data?.result;
          if (data) {
            sessionStorage.setItem("accessToken", data?.accessToken);
            sessionStorage.setItem("userName", data?.name);
            sessionStorage.setItem("userId", data?.userId);
          }
          setIsLoading(false);
          navigate("/items");
        } else {
          alert(response?.data?.message);
        }
      } catch (error) {
        console.error("Error during login:", error);
        alert("An error occurred while logging in. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please enter username and password.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button onClick={() => handleLogin()}>Login</button>
      </div>
    </div>
  );
};

export default Login;
