import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import SplashScreen from "./components/SplashScreen";
import LoadingSpinner from "./constants/LoadingSpinner";
import { useLoading } from "./constants/LoadingContext"; 
import "./App.css";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Login from "./components/Login";
import Home from "./components/Home";
import Items from "./components/Items";
import CreatePurchase from "./components/pages/Purchase/CreatePurchase";
import CreateProducts from "./components/pages/Products/CreateProducts";
import SalesOrderList from "./components/pages/SalesOrder/SalesOrderList";
import CreateSalesOrder from "./components/pages/SalesOrder/CreateSalesOrder";
import EditSalesOrder from "./components/pages/SalesOrder/EditSalesOrder";
import PurchaseOrderReport from "./components/pages/SalesOrder/PurchaseOrderReport";
import CustomerOrderReport from "./components/pages/SalesOrder/CustomerOrderReport";

function Layout({ children }) {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  return (
    <div className="app-container">
      {!isLoginPage && <Sidebar />}
      <div className="main-content">
        {!isLoginPage && <Header />}
        {children}
      </div>
    </div>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/splash" element={<SplashScreen />} />
      <Route path="/" element={  <Layout> <Login /> </Layout> } />
      <Route path="/items" element={  <Layout> <Items /> </Layout> } />
      <Route path="/purchases" element={  <Layout> <Home /> </Layout> } />
      <Route path="/createPurchase" element={  <Layout> <CreatePurchase /> </Layout> } />
      <Route path="/CreateProducts" element={  <Layout> <CreateProducts /> </Layout> } />
      <Route path="/SalesOrder" element={  <Layout> <SalesOrderList /> </Layout> } />
      <Route path="/CreateSalesOrder" element={  <Layout> <CreateSalesOrder /> </Layout> } />
      <Route path="/EditSalesOrder/:id" element={  <Layout> <EditSalesOrder /> </Layout> } />
      <Route path="/PurchaseOrderReport" element={  <Layout> <PurchaseOrderReport /> </Layout> } />
      <Route path="/CustomerOrderReport" element={  <Layout> <CustomerOrderReport /> </Layout> } />
    </Routes>
  );
}

function App() {
  const { isLoading } = useLoading();

  return (
    <Router>
      {isLoading && <LoadingSpinner />}
      <AppRoutes />
    </Router>
  );
}

export default App;
