import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate an authentication check (e.g., token in local storage)
    const isLoggedIn = localStorage.getItem("authToken");

    // Redirect based on login status
    if (isLoggedIn) {
      navigate("/items"); // Redirect to the home page or dashboard
    } else {
      navigate("/"); // Redirect to the login page
    }
  }, [navigate]);

  const styles = {
    splash: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f5f5f5",
      fontSize: "24px",
      fontWeight: "bold",
      color: "#333",
    },
  };

  return (
    <div style={styles.splash}>
      <h1>Loading...</h1>
      {/* Add any desired loading animation or logo */}
    </div>
  );
};

export default SplashScreen;
