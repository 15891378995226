import React from "react";
import { useNavigate } from "react-router-dom"; 
import "./modal.css";

const UserModal = ({ onClose }) => {
  const navigate = useNavigate(); 
  const name = sessionStorage.getItem('userName'); 

  const handleLogout = () => {
    sessionStorage.clear(); 
    navigate("/"); 
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <h2>User Details</h2>
        <img
          src="https://via.placeholder.com/100"
          alt="User"
          className="user-photo"
        />
        <p>Name: {name}</p>
        {/* Logout Button */}
        <button onClick={handleLogout} className="logout-btn">
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserModal;
