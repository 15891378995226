import React from "react";
import "./LoadingSpinner.css";
import { FaSpinner } from "react-icons/fa";

const LoadingSpinner = () => {
  return (
    <div className="loading-overlay">
      <div className="spinner">
        <FaSpinner className="spinner-icon" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
