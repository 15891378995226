// CustomerOrderReport.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "./CreateSalesOrder.css";
import "react-datepicker/dist/react-datepicker.css";
import { buildGetData, getFullURL, API_PATH, buildPostData } from "../../../constants/constants";
import { useLoading } from "../../../constants/LoadingContext";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { FaDownload, FaArrowLeft } from "react-icons/fa";

const CustomerOrderReport = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  const [formData, setFormData] = useState({ deliveryDate: "" });
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isProductsVisible, setIsProductsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fetchLineItems = async (deliveryDate) => {
    setErrors({});
    try {
      setIsLoading(true);
      const date = new Date(deliveryDate);
      const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
      const res = await buildGetData(getFullURL(`/sales/individualorder?deliveryDate=${formattedDate}`));

      if (res?.data?.status === "success") {
        const data = res.data.result;
        const transformedData = transformData(data);
        setData(transformedData);
        setIsProductsVisible(true);
      } else {
        alert("Failed to fetch buyer list");
        setIsProductsVisible(false);
      }
    } catch (error) {
      console.error("Error fetching buyer list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function transformData(data) {
    return Object.values(data.rows.reduce((acc, row) => {
      if (!acc[row.customerId]) {
        acc[row.customerId] = {
          customerId: row.customerId,
          customerName: row.customerName,
          customerNo: row.customerNo,
          products: []
        };
      }
      acc[row.customerId].products.push({
        salesOrderId: row.salesOrderId,
        lineItemId: row.salesOrderLineItemId,
        productId: row.productId,
        qty: row.qty,
        productNo: row.productNumber,
        productName: row.productName,
        uom: row.uom,
        status: row.isAvailable ? row.isAvailable === 'Yes' ? "packed" : "unavailable" : "",
        comments: row.comments ?? ""
      });
      return acc;
    }, {}));
  }

  const generatePDF = () => {
    const doc = new jsPDF('p', 'mm', 'a4'); // 'p' for portrait, 'mm' for millimeters, 'a4' for A4 size
    const pageWidth = doc.internal.pageSize.getWidth(); // Width of the A4 page
    const margin = 20; // Left margin
    const usableWidth = pageWidth - 2 * margin; // Usable width for the table content
    const date = new Date(formData.deliveryDate);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
  
    // Add Title
    doc.setFont('times', 'bold');
    doc.setFontSize(16);
    const title = 'Customer Orders Report';
    doc.text(title, pageWidth / 2, 20, { align: 'center' });
  
    // Add Delivery Date
    doc.setFontSize(12);
    const deliveryDateText = `Delivery Date: ${formattedDate}`;
    doc.text(deliveryDateText, pageWidth / 2, 30, { align: 'center' });
  
    let currentY = 40;
  
    data.forEach((customer) => {
      // Add Customer Information, shifted slightly to the right
      doc.setFont('times', 'bold');
      doc.text(`Restaurant/Customer Name: ${customer.customerName}`, margin + 5, currentY); // Shifted to the right
      currentY += 10;
      // Prepare table data
      const tableHeaders = [['Product Name', 'Quantity', 'UOM', 'Is Available', 'Comments']];
      const tableData = customer.products.map((product) => [
        product.productName,
        product.qty.toString(),
        product.uom,
        product.status || 'N/A',
        product.comments || 'N/A',
      ]);
  
      // Render table starting from the left margin (move it left by modifying `startX`)
      autoTable(doc, {
        head: tableHeaders,
        body: tableData,
        startY: currentY, // Start from the same Y position
        startX: margin, // Start from the left margin
        theme: 'grid',
        tableWidth: 'auto', // Ensure table doesn't overflow
        styles: { halign: 'center', cellPadding: 2 },
        headStyles: {
          fillColor: [40, 167, 69],
          textColor: [255, 255, 255],
          fontSize: 10,
        },
        bodyStyles: { fontSize: 9, cellWidth: 'wrap' },
        columnStyles: {
          0: { cellWidth: usableWidth * 0.3 }, // Product Name (30% of page width)
          1: { cellWidth: usableWidth * 0.15 }, // Quantity (15%)
          2: { cellWidth: usableWidth * 0.15 }, // UOM (15%)
          3: { cellWidth: usableWidth * 0.2 }, // Is Available (20%)
          4: { cellWidth: usableWidth * 0.2 }, // Comments (20%)
        },
      });
  
      currentY = doc.lastAutoTable.finalY + 10; // Update Y position after the table
  
      if (currentY > doc.internal.pageSize.getHeight() - margin) {
        doc.addPage(); // Add new page if content exceeds page height
        currentY = margin;
      }
    });
  
    // Save PDF
    doc.save(`customer-orders-report-${formattedDate}.pdf`);
  };


  const handleRadioChange = (e, customerId, productId) => {
    const value = e.target.value;
    setData((prevCustomers) =>
      prevCustomers.map((customer) => {
        if (customer.customerId === customerId) {
          return {
            ...customer,
            products: customer.products.map((product) =>
              product.productId === productId
                ? { ...product, status: value }
                : product
            )
          };
        }
        return customer;
      })
    );
  };
  
  const handleInputChange = (customerId, productId, field, value) => {
    setData((prevCustomers) =>
      prevCustomers.map((customer) => {
        if (customer.customerId === customerId) {
          return {
            ...customer,
            products: customer.products.map((product) =>
              product.productId === productId
                ? { ...product, [field]: value }
                : product
            )
          };
        }
        return customer;
      })
    );
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const payload = data.flatMap((customer) =>
      customer.products
        .filter((product) => product.status)
        .map((product) => ({
          salesOrderId: product.salesOrderId,
          lineItemId: product.lineItemId,
          available: product.status === 'packed' ? "Yes" : product.status === 'unavailable' ? "No" : '',
          comments: product.comments || "",
        }))
    );

    if (payload.length === 0) {
      alert("No valid products selected to submit.");
      return;
    }

    const request = {
      'customerOrderItems': payload
    };

    try {
      setIsLoading(true);
      const res = await buildPostData(getFullURL(API_PATH.SAVE_CUSTOMER_ORDER_FORM_URL), request);
      if (res?.data?.status === "success") {
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error saving order:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-purchase">
      <form className="purchase-form" onSubmit={handleSubmit}>
        <h2>Customer Orders Report</h2>

        <div className="form-row">
          <div className="form-group">
            <label>
              Delivery Date<span className="required">*</span>
            </label>
            <DatePicker
              selected={formData.deliveryDate}
              onChange={(date) => {
                setFormData((prevData) => ({ ...prevData, deliveryDate: date }));
                setErrors({});
                fetchLineItems(date);
              }}
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
            />
            {errors.deliveryDate && (
              <span className="error-message">{errors.deliveryDate}</span>
            )}
          </div>
          {isProductsVisible && (
            <div>
              <button
                type="button"
                onClick={generatePDF}
                style={{ marginTop: 20, marginLeft: 50, backgroundColor: '#28a745' }}
                className="add-item-btn"
              >
                <FaDownload style={{ marginRight: 8 }} /> Print
              </button>
            </div>
          )}
        </div>

        {isProductsVisible && (
          <div>
            <h2>Products</h2>
            {data.map((customer) => (
              <div key={customer.customerId}>
                <h3>Restaurant/Customer Name: {customer.customerName}</h3>
                <table className="item-table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>UOM</th>
                      <th>Packed/Unavailable</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customer.products.map((product) => (
                      <tr
                        key={product.productId}
                        style={{
                          backgroundColor:
                            product.status === "packed"
                              ? "lightgreen"
                              : product.status === "unavailable"
                                ? "lightcoral"
                                : ""
                        }}
                      >
                        <td>{product.productName}</td>
                        <td>{product.qty}</td>
                        <td>{product.uom}</td>
                        <td>
                          <label>
                            <input
                              type="radio"
                              name={`status-${customer.customerId}-${product.productId}`}
                              value="packed"
                              checked={product.status === "packed"}
                              onChange={(e) => handleRadioChange(e, customer.customerId, product.productId)}
                            />
                            Packed
                          </label>
                          <label style={{ marginLeft: "10px" }}>
                            <input
                              type="radio"
                              name={`status-${customer.customerId}-${product.productId}`}
                              value="unavailable"
                              checked={product.status === "unavailable"}
                              onChange={(e) => handleRadioChange(e, customer.customerId, product.productId)}
                            />
                            Unavailable
                          </label>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={product.comments}
                            onChange={(e) =>
                              handleInputChange(
                                customer.customerId,
                                product.productId,
                                "comments",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        )}

        <div className="form-footer">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="back-btn"
          >
            <FaArrowLeft style={{ marginRight: 8 }} /> Back
          </button>
          {isProductsVisible && (
            <button
              type="submit"
              className="submit-btn"
            >
              Submit
            </button>
          )}
        </div>
      </form>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Customer Order Report Saved/Updated Successfully</p>
            <button className="modal-btn" onClick={() => {
              setShowModal(false);
              navigate("/SalesOrder");
            }}>
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerOrderReport;
