import React, { useState, useEffect } from "react";
import "./ProductsItemModal.css"; // Your existing CSS file
import { buildGetData, getFullURL, API_PATH } from "../../../constants/constants";

const ProductsItemModal = ({ onClose, onAddItems }) => {
  const [items, setItems] = useState([]); // List of items fetched from the API
  const [selectedItems, setSelectedItems] = useState([]); // Selected items
  const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering items

  // Fetch products from API
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await buildGetData(getFullURL(API_PATH.PRODUCTS_LIST_URL));
        if (res?.data?.status === "success") {
          setItems(res.data.result.rows);
        } else {
          alert("Failed to fetch products.");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        alert("An error occurred while fetching the products.");
      }
    };

    fetchItems();
  }, []);

  // Search function to filter items
  const filteredItems = items.filter((item) =>
    item.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.productNo.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Toggle item selection
  const toggleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        {/* Close Icon */}
        <span className="close-icon" onClick={onClose}>&times;</span>

        <h3>Select Products</h3>

        {/* Search bar */}
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search Products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {/* Table Container with scroll functionality */}
        <div className="table-container">
          <table className="item-table">
            <thead>
              <tr>
                <th>Select</th>
                <th>Product No</th>
                <th>Product Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                  <tr key={item.productId}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item)}
                        onChange={() => toggleSelectItem(item)}
                      />
                    </td>
                    <td>{item.productNo}</td>
                    <td>{item.productName}</td>
                    <td>{item.status === 0 ? "Active" : "In-Active"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No items found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Modal action buttons */}
        <div className="modal-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={() => onAddItems(selectedItems)}>Add</button>
        </div>
      </div>
    </div>
  );
};

export default ProductsItemModal;
