import React, { useState } from "react";
import { FaUser } from "react-icons/fa"; 
import UserModal from "./UserModal";
import SettingsModal from "./SettingsModal";
import "./Header.css";

const Header = () => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  //const name = sessionStorage.getItem('userName'); 

  return (
    <div className="header">
      <h1 className="header-title">Deliver Fresh</h1>
      <div className="header-icons">
       
        {/* User Icon */}
        <FaUser
          className="icon"
          onClick={() => setShowUserModal(true)}
          title="User Profile"
        />
      </div>
      {/* Modals */}
      {showUserModal && <UserModal onClose={() => setShowUserModal(false)} />}
      {showSettingsModal && (
        <SettingsModal onClose={() => setShowSettingsModal(false)} />
      )}
    </div>
  );
};

export default Header;
